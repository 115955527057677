export const SYSTEM_ADMIN_PATH = {
  SYSTEM_CAR_BRAND: '/car_brand',
  SYSTEM_TELEPHONE_CODE: '/telephone_code',
  SYSTEM_COUNTRY_CODE: '/country_code',
  SYSTEM_COUNTRY_CODE_ADD: '/country_code/:countryId',
  SYSTEM_COMPANY: '/company',
  SYSTEM_COMPANY_DETAIL: '/company/:companyId/:companyName',
  SYSTEM_ACCOUNT: '/sys_account',
  SYSTEM_CS_MODEL: '/cs_model',
  SYSTEM_CS_MODEL_DETAIL: '/cs_model/:modelId/:modelName',
  SYSTEM_SETTING: '/setting',
} as const;

export const COMPANY_PATH = {
  COMPANY_CHARGING_STATIONS: '/com_charging_stations',
  COMPANY_FIELDS: '/com_fields',
  COMPANY_FIELDS_DETAIL: '/com_fields/:fieldId',
  COMPANY_CS_MODEL: '/com_cs_model',
  COMPANY_ACCOUNT: '/com_account',
  COMPANY_SETTING: '/setting',
} as const;

export const FIELD_PATH = {
  FIELD_OVERVIEW: '/overview',
  FIELD_FIELDS: '/fields',
  FIELD_FIELDS_DETAIL: '/fields/:fieldId',
  FIELD_FIELDS_FLOW_GROUPS: '/fields/:fieldId/:fieldsName/flow_groups',
  FIELD_CHARGING_STATIONS: '/charging_stations',
  FIELD_ERROR_REPORT: '/charging_stations/error_report',
  FIELD_ACTIVITY_LOG: '/charging_stations/activity_log',
  FIELD_PRICE_PLAN: '/price_plan',
  FIELD_PRICE_PLAN_DETAIL: '/price_plan/:fieldId/:fieldsName',
  FIELD_CHARGE_PLAN: '/charge_plan',
  FIELD_MEMBERS: '/members',
  FIELD_CUSTOMER_FEEDBACK: '/members/customer_feedback',
  FIELD_SETTING: '/setting',
  FIELD_ACCOUNT: '/account',
} as const;

export const NO_LOGIN_PATH = {
  LOGIN: '/login',
  FORGET_PWD: '/forgetPassword/:uuid',
} as const;

export const COMMON_PATH = {
  MAIN: '/',
  USER_PROFILE: '/profile',
} as const;

export default {
  ...COMMON_PATH,
  ...COMPANY_PATH,
  ...FIELD_PATH,
  ...NO_LOGIN_PATH,
  ...SYSTEM_ADMIN_PATH,
};
