import { Action } from 'redux';
import { COMPANY_MENU_CONFIG } from '~~components/Menu/menuConstants';
import { ROLES } from '~~utils/Constants';
import { userTypes } from './types';

type IUserLoaderVisibleAction = Action<typeof userTypes.SET_LOADER_VISIBLE> & { visible: boolean };
export function setLoaderVisible(visible: boolean): IUserLoaderVisibleAction {
  return { type: userTypes.SET_LOADER_VISIBLE, visible };
}

export type IUserData = {
  last_name: string,
  first_name: string,
  user_active_status: string,
  email: string,
  phone_number: string,
  phone_number_country_code: string,
  avatar_image_url: string,
  user_type: keyof typeof ROLES,
  menu: {
    parent: keyof typeof COMPANY_MENU_CONFIG,
    child?: string,
  }[],
};

type IUserDataAction = Action<typeof userTypes.SET_USER_DATA> & { data: IUserData };
export function setUserData(data: IUserData): IUserDataAction {
  return { type: userTypes.SET_USER_DATA, data };
}

export type IUserActions = IUserLoaderVisibleAction | IUserDataAction;
