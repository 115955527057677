import i18n from 'i18next';
import { initReactI18next } from 'react-i18next';

import enUS from './en-US.json';
import zhTW from './zh-TW.json';

export const defaultNS = 'translations';
export const resources = {
  'en-US': {
    translations: enUS,
  },
  'zh-TW': {
    translations: zhTW,
  },
} as const;

i18n.use(initReactI18next).init({
  resources,
  lng: localStorage.getItem('language') || navigator.language || 'zh-TW', // 預設語言
  fallbackLng: 'zh-TW', // 如果當前切換的語言沒有對應的翻譯則使用這個語言
  defaultNS,
});

export default i18n;
