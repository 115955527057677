import React from 'react';
import { I18nextProvider } from 'react-i18next';
import { Provider } from 'react-redux';
import { BrowserRouter } from 'react-router-dom';
import i18n from './locales/i18n';
import store from './utils/Store';
import Routes from './routes/routes';
import './App.css';
import 'antd/dist/antd.css';

const App = () => (
  <Provider store={store}>
    <I18nextProvider i18n={i18n}>
      <BrowserRouter>
        <Routes />
      </BrowserRouter>
    </I18nextProvider>
  </Provider>
);

export default App;
