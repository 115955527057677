import React, { Suspense, lazy } from 'react';
import type { RouteObject } from 'react-router-dom';
import { useRoutes } from 'react-router-dom';
import Loader from '~~components/Loader/Loader';
import PATH from './PathConstants';
import LoadingPage from './LoadingPage';
import Authentication from './Authentication';

const WaitingComponent = (Component: React.LazyExoticComponent<React.ComponentType>) => (
  <Suspense fallback={<LoadingPage />}>
    <Component />
  </Suspense>
);

const appRoutes: RouteObject[] = [
  {
    path: PATH.LOGIN,
    element: WaitingComponent(lazy(() => import('../modules/Login/Login'))),
  },
  // Page with Menu
  {
    path: PATH.MAIN,
    element: WaitingComponent(lazy(() => import('../modules/MainPage/MainPage'))),
    children: [
      {
        path: PATH.COMPANY_FIELDS,
        element: WaitingComponent(lazy(() => import('../modules/Fields/Fields'))),
      },
      {
        path: PATH.COMPANY_FIELDS_DETAIL,
        element: WaitingComponent(lazy(() => import('../modules/Fields/FieldsDetail'))),
      },
      {
        path: PATH.COMPANY_SETTING,
        element: WaitingComponent(lazy(() => import('../modules/Setting/Setting'))),
      },
      {
        path: PATH.COMPANY_ACCOUNT,
        element: WaitingComponent(lazy(() => import('../modules/CompanyManager/Account/Account'))),
      },
      {
        path: PATH.COMPANY_CHARGING_STATIONS,
        element: WaitingComponent(lazy(() => import('../modules/ChargingStations/ChargingStations'))),
      },
      {
        path: PATH.COMPANY_CS_MODEL,
        element: WaitingComponent(lazy(() => import('../modules/CompanyManager/CSModel/CSModel'))),
      },
      {
        path: PATH.FIELD_OVERVIEW,
        element: WaitingComponent(lazy(() => import('../modules/Overview/Overview'))),
      },
      {
        path: PATH.FIELD_FIELDS,
        element: WaitingComponent(lazy(() => import('../modules/Fields/Fields'))),
      },
      {
        path: PATH.FIELD_FIELDS_DETAIL,
        element: WaitingComponent(lazy(() => import('../modules/Fields/FieldsDetail'))),
      },
      {
        path: PATH.FIELD_FIELDS_FLOW_GROUPS,
        element: WaitingComponent(lazy(() => import('../modules/Fields/FlowGroups/FlowGroups'))),
      },
      {
        path: PATH.FIELD_CHARGING_STATIONS,
        element: WaitingComponent(lazy(() => import('../modules/ChargingStations/ChargingStations'))),
      },
      {
        path: PATH.FIELD_MEMBERS,
        element: WaitingComponent(lazy(() => import('../modules/Members/Members'))),
      },
      {
        path: PATH.FIELD_CUSTOMER_FEEDBACK,
        element: WaitingComponent(lazy(() => import('../modules/Members/CustomerFeedback'))),
      },
      {
        path: PATH.FIELD_ERROR_REPORT,
        element: WaitingComponent(lazy(() => import('../modules/ChargingStations/ErrorReport'))),
      },
      {
        path: PATH.FIELD_ACTIVITY_LOG,
        element: WaitingComponent(lazy(() => import('../modules/ChargingStations/ActivityLog'))),
      },
      {
        path: PATH.FIELD_PRICE_PLAN,
        element: WaitingComponent(lazy(() => import('../modules/PricePlan/PricePlan'))),
      },
      {
        path: PATH.FIELD_PRICE_PLAN_DETAIL,
        element: WaitingComponent(lazy(() => import('../modules/PricePlan/PricePlanDetail'))),
      },
      {
        path: PATH.FIELD_SETTING,
        element: WaitingComponent(lazy(() => import('../modules/Setting/Setting'))),
      },
      // {
      //   path: PATH.FIELD_ACCOUNT,
      //   element: WaitingComponent(lazy(() => import('../modules/SystemAdmin/Account/Account'))),
      // },
      {
        path: PATH.SYSTEM_CAR_BRAND,
        element: WaitingComponent(lazy(() => import('../modules/SystemAdmin/CarBrand/CarBrand'))),
      },
      {
        path: PATH.SYSTEM_TELEPHONE_CODE,
        element: WaitingComponent(lazy(() => import('../modules/SystemAdmin/TelephoneCode/TelephoneCode'))),
      },
      {
        path: PATH.SYSTEM_COUNTRY_CODE,
        element: WaitingComponent(lazy(() => import('../modules/SystemAdmin/CountryCode/CountryCode'))),
      },
      {
        path: PATH.SYSTEM_COUNTRY_CODE_ADD,
        element: WaitingComponent(lazy(() => import('../modules/SystemAdmin/CountryCode/CountryCodeAddCountry'))),
      },
      {
        path: PATH.SYSTEM_COMPANY,
        element: WaitingComponent(lazy(() => import('../modules/SystemAdmin/Company/Company'))),
      },
      {
        path: PATH.SYSTEM_COMPANY_DETAIL,
        element: WaitingComponent(lazy(() => import('../modules/SystemAdmin/Company/CompanyDetail'))),
      },
      {
        path: PATH.SYSTEM_ACCOUNT,
        element: WaitingComponent(lazy(() => import('../modules/SystemAdmin/Account/Account'))),
      },
      {
        path: PATH.SYSTEM_SETTING,
        element: WaitingComponent(lazy(() => import('../modules/Setting/Setting'))),
      },
      {
        path: PATH.SYSTEM_CS_MODEL,
        element: WaitingComponent(lazy(() => import('../modules/SystemAdmin/CSModel/CSModel'))),
      },
      {
        path: PATH.SYSTEM_CS_MODEL_DETAIL,
        element: WaitingComponent(lazy(() => import('../modules/SystemAdmin/CSModel/CSModelDetail'))),
      },
      {
        path: '/*',
        element: WaitingComponent(lazy(() => import('../modules/EmptyPage/EmptyPage'))),
      },
    ],
  },
];

const routes = () => {
  const element = useRoutes(appRoutes);
  return (
    <Authentication>
      {element}
      <Loader />
    </Authentication>
  );
};

export default routes;
