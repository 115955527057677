import React from 'react';
import styled from 'styled-components';
import { Spin } from 'antd';
import { useSelector } from 'react-redux';
import { useTranslation } from 'react-i18next';
import store, { IRootState } from '~~utils/Store';
import { setLoaderVisible } from '../../actions/user';

type IProps = {
  active: boolean,
};
const LoaderDropback = styled.div<IProps>`
  ${(props) => !props.active && `
    display: none;
  `}
`;

const LoaderBackground = styled.div`
  top:0;
  left:0;
  position: fixed;
  width: 100%;
  height: 100%;
  background-color: rgba(0, 0, 0, .02);
  z-index:2070;
  display: flex;

  justify-content: center;
  align-items: center;
  overflow: auto;
`;
const LoaderWrapper = styled.div`
  z-index:2071;
  width: 100%;
  height: 100%;
  border-radius: 10px;
  background-color: rgba(0, 0, 0, .05);
  font-size: 1.5rem;
  display: flex;
  justify-content: center;
  align-items: center;
  flex-direction: column;
  color: #fff;
`;

const Loader = () => {
  const active = useSelector<IRootState, boolean>((state) => state.user.isLoading);
  const { t } = useTranslation();
  return (
    <LoaderDropback active={active}>
      <LoaderBackground>
        <LoaderWrapper>
          <Spin tip={t('COMMON.LOADING')} size="large" />
        </LoaderWrapper>
      </LoaderBackground>
    </LoaderDropback>
  );
};

export const toggleLoader = (isOpen = false) => {
  if (isOpen) {
    store.dispatch(setLoaderVisible(true));
  } else {
    store.dispatch(setLoaderVisible(false));
  }
};

export default Loader;
