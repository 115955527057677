import { Reducer } from 'redux';
import { userTypes } from '../actions/types';
import { IUserActions, IUserData } from '../actions/user';

interface IUserInitialState {
  isLoading: boolean,
  userData: IUserData,
}
const userInitialState: IUserInitialState = {
  isLoading: false,
  userData: {
    last_name: '',
    first_name: '',
    user_active_status: '',
    email: '',
    phone_number: '',
    phone_number_country_code: '',
    avatar_image_url: '',
    user_type: 0,
    menu: [],
  },
};

const user: Reducer<IUserInitialState, IUserActions> = (state = userInitialState, action) => {
  switch (action.type) {
    case userTypes.SET_LOADER_VISIBLE:
      return {
        ...state,
        isLoading: action.visible,
      };
    case userTypes.SET_USER_DATA:
      return {
        ...state,
        userData: {
          ...userInitialState,
          ...action.data,
        },
      };
    default:
      return state;
  }
};

export default user;
