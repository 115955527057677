export const LOCAL_KEYS = {
  SYNC_SESSION: 's_s',
  UPDATE_SESSION: 'u_s',
  REMOVE_SESSION: 'r_s',
  STORE_SESSION_DATA: 's_s_d',
};

export const SESSION_KEYS = {
  TOKEN: 'w_t',
};

type IStorage = 'local';

export function isLogin() {
  const token = sessionStorage.getItem(SESSION_KEYS.TOKEN) || localStorage.getItem(SESSION_KEYS.TOKEN);
  return token !== '' && token !== 'null' && token !== null;
}

export function getToken(storage?: IStorage) {
  if (storage === 'local' && localStorage.getItem(SESSION_KEYS.TOKEN)) return localStorage.getItem(SESSION_KEYS.TOKEN);
  return sessionStorage.getItem(SESSION_KEYS.TOKEN);
}

export function deleteToken() {
  if (localStorage.getItem(SESSION_KEYS.TOKEN) !== null) localStorage.removeItem(SESSION_KEYS.TOKEN);
  sessionStorage.removeItem(SESSION_KEYS.TOKEN);
  localStorage.setItem(LOCAL_KEYS.REMOVE_SESSION, Date.now().toString());
}

export function setToken(token: string, storage?: IStorage) {
  if (storage === 'local') localStorage.setItem(SESSION_KEYS.TOKEN, token);
  sessionStorage.setItem(SESSION_KEYS.TOKEN, token);
  localStorage.setItem(LOCAL_KEYS.UPDATE_SESSION, `${Date.now()},${token}`);
}

export default {};
