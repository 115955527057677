import React, { useEffect } from 'react';
import { useNavigate, useLocation, matchPath } from 'react-router-dom';
import { LOCAL_KEYS, SESSION_KEYS, isLogin } from '~~utils/Auth';
import { NO_LOGIN_PATH, FIELD_PATH } from './PathConstants';

const NO_LOGIN_PAGE = Object.values(NO_LOGIN_PATH);

const Authentication = ({ children }: { children: React.ReactNode }) => {
  const navigate = useNavigate();
  const { pathname } = useLocation();

  const checkLogin = async () => {
    const loginFlag = isLogin();
    if (!loginFlag && !NO_LOGIN_PAGE.find((path) => matchPath(pathname, path))) {
      // 沒有登入，且網址不在免登入白名單之內，將網址導至登入頁
      sessionStorage.removeItem(SESSION_KEYS.TOKEN);
      navigate(NO_LOGIN_PATH.LOGIN);
    } else if (loginFlag && (pathname === NO_LOGIN_PATH.LOGIN || pathname === '/')) {
      // 有登入，但是還在登入頁，轉導到系統首頁
      navigate(FIELD_PATH.FIELD_SETTING);
    }
  };

  useEffect(() => {
    localStorage.setItem(LOCAL_KEYS.SYNC_SESSION, Date.now().toString());
    checkLogin();
    window.onstorage = (e) => {
      if (!e.newValue) return;

      // GET_SESSION
      // 從其他 tab 取得資料放到 localStorage，再刪除
      if (e.key === LOCAL_KEYS.SYNC_SESSION) {
        const key = sessionStorage.getItem(SESSION_KEYS.TOKEN);
        if (key) {
          localStorage.setItem(LOCAL_KEYS.STORE_SESSION_DATA, key);
          localStorage.removeItem(LOCAL_KEYS.STORE_SESSION_DATA);
          checkLogin();
        }
      }

      // STORE_SESSION_DATA
      // 監聽 localStorage 被寫入，寫到 sessionStorage
      if (e.key === LOCAL_KEYS.STORE_SESSION_DATA && !!e.newValue) {
        sessionStorage.setItem(SESSION_KEYS.TOKEN, e.newValue);
        localStorage.removeItem(LOCAL_KEYS.SYNC_SESSION);
        checkLogin();
      }

      // UPDATE_SESSION
      // 更換TOKEN (change workspace / login)
      if (e.key === LOCAL_KEYS.UPDATE_SESSION && !!e.newValue) {
        const token = e.newValue.split(',')[1];
        sessionStorage.setItem(SESSION_KEYS.TOKEN, token);
        localStorage.removeItem(LOCAL_KEYS.UPDATE_SESSION);

        if (isLogin()) {
          // 切換workspace
          window.location.reload();
        } else {
          // 登入
          // TODO: 可能要判斷登入者身分導引到其該去的頁面
          navigate(FIELD_PATH.FIELD_SETTING);
        }
      }
      // REMOVE_SESSION
      // 監聽其他 tab 登出
      if (e.key === LOCAL_KEYS.REMOVE_SESSION) {
        sessionStorage.removeItem(SESSION_KEYS.TOKEN);
        localStorage.removeItem(LOCAL_KEYS.REMOVE_SESSION);
        navigate(NO_LOGIN_PATH.LOGIN);
      }
    };
  }, []);
  return <div>{children}</div>;
};

export default Authentication;
